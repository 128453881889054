import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <h1>Well...</h1>
    <p>This is where we begin inventing psychohistory. First we'll invent the first foundation, then we'll invent the second foundation. TODO: Find an Emperor Cleon to fund the project</p>
    <p>Now to go invent the prime radiant. First step: figure out how to insert google sheet data in here.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link>
  </Layout>
)

export default IndexPage
